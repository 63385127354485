/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';

import { globalState } from '../store/globalState';


function isAuthenticated() {
  // Replace this with your actual authentication logic
  return !!localStorage.getItem('token');
}

const routes = [
  { 
    path: '/', 
    name: 'Home', 
    
    component: () => import('../Pages/HomePage.vue'), 
    meta: { title: 'Home' } 
  },
  { 
    path: '/aboutus', 
    name: 'About Us', 
    
    component: () => import('../Pages/AboutUsPage.vue'), 
    meta: { title: 'About Us' } 
  },
  { 
    path: '/gfgold', 
    name: 'GF Gold', 
    
    component: () => import('../Pages/GFGoldPage.vue'), 
    meta: { title: 'GF Gold' } 
  },
  { 
    path: '/services', 
    name: 'Our Services', 
    
    component: () => import('../Pages/ServicesPage.vue'), 
    meta: { title: 'Our Services' } 
  },
  { 
    path: '/contactus', 
    name: 'Contact Us', 
    
    component: () => import('../Pages/ContactUsPage.vue'), 
    meta: { title: 'Contact Us' } 
  },
  { 
    path: '/online-market', 
    name: 'Online Market', 
    
    component: () => import('../Pages/OnlineMarketPage.vue'), 
    meta: { title: 'Online Market' } 
  },
  { 
    path: '/analysis', 
    name: 'Analysis', 
    
    component: () => import('../Pages/AnalysisPage.vue'), 
    meta: { title: 'Analysis' } 
  },
  { 
    path: '/article', 
    name: 'Article', 
    
    component: () => import('../Pages/ArticlePage.vue'), 
    meta: { title: 'Article' } 
  },
  { 
    path: '/hedging-faq', 
    name: 'Hedging Faq', 
    
    component: () => import('../Pages/HedgingFaqPage.vue'), 
    meta: { title: 'Hedging Faq' } 
  },
  { 
    path: '/articleDetail', 
    name: 'ArticleDetail', 
    
    component: () => import('../components/ViewArticleDetail.vue'), 
    meta: { title: 'Detail' } 
  },
  { 
    path: '/aanalysisDetail', 
    name: 'AanalysisDetail', 
    
    component: () => import('../components/ViewAnalysisDetail.vue'), 
    meta: { title: 'Detail' } 
  },
  {
    path: '/:langCode/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../Pages/PrivacyPolicyPage.vue'),
    meta: (route) => ({ title: route.params.langCode === 'cn' ? '隐私政策' : 'Privacy Policy' }),
  },
  {
    path: '/:langCode/terms',
    name: 'Terms',
    component: () => import('../Pages/TermsPage.vue'),
    meta: (route) => ({ title: route.params.langCode === 'cn' ? '条款和条件' : 'Terms and Conditions' }),
  },
];



const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top on route change
    return { top: 0 };
  },
});





export default router;
